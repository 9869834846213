<template>
  <div class="abnormal-notice-detail container">
    <!-- 主体 -->
    <div class="main">
      <!-- 标题 -->
      <div class="title">
        <h1>{{exceptionNoticeInfo.exceptionNoticeTitle}}</h1>
        <div class="release-date">
          <span>发布时间：</span>
          <span>{{exceptionNoticeInfo.publishTime|filterTime}}</span>
        </div>
      </div>

      <!-- 招标信息 -->
      <div class="tender-info">
        <div class="item">
          招标项目编号：
          <span>{{exceptionNoticeInfo.bidProjectNo}}</span>
        </div>
        <br />
        <div class="item">
          招标项目名称：
          <span>{{exceptionNoticeInfo.bidProjectName}}</span>
        </div>
        <br />
        <div class="item">
          异常类型：
          <span>{{exceptionNoticeInfo.exceptionNoticeType|filterAbnormalType}}</span>
        </div>
        <br />
        <div class="item">
          是否电子标：
          <span>{{bidSectionInfo.electronic|filterElectronicType}}</span>
        </div>
      </div>

      <!-- 内容 -->
      <div class="content">
        <h2 class="content-title">异常情况描述</h2>
        <div class="content-text" v-html="exceptionNoticeInfo.exceptionDescribe"></div>
        <FileList :title="异常公告附件" :fileList="exceptionNoticeInfo.attachFilePath"></FileList>
<!-- 
        <div class="content-file">
          <p>
            <i class="iconfont icon-fujian"></i>
            <span>异常公告附件：</span>
          </p>
          <template v-if="exceptionNoticeInfo.attachFilePath.length > 0">
            <a :href="baseURL+v" target="_blank" 
              v-for="(v,i) in exceptionNoticeInfo.attachFilePath" :key="i" class="file_list">
              <span>{{ v.substring( v.lastIndexOf('/')+1 ) }}</span>
              <span>
                <img src="@/assets/images/download/ic_xz@2x.png" />
                <el-button type="text">下载</el-button>
              </span>
            </a>
          </template>
          <a v-else class="file_list" style="justify-content: center;">无附件</a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import tradeInfoApi from "@/api/tradeInfo";
import { mapState } from "vuex";
import FileList from '@/components/FileList/index.vue'

export default {
  components: {
    FileList
  },
  async created() {
    // 获取地址栏传过来的参数
    this.query = this.$route.query;
    // 根据参数获取详情
    let { data } = await tradeInfoApi.findTradeDetail(this.query);
    this.bidSectionInfo = data.tbBidSectionInfo;
    this.exceptionNoticeInfo = data.tbExceptionNoticeInfo;
    this.exceptionNoticeInfo.attachFilePath = JSON.parse(
      this.exceptionNoticeInfo.attachFilePath
    );
  },
  data() {
    return {
      query: { id: "", type: "", isGov: "" },
      bidSectionInfo: {},
      exceptionNoticeInfo: {
        attachFilePath: []
      }
    };
  },
  computed: {
    ...mapState(["baseURL", "bidderURL"])
  },
  filters: {
    filterElectronicType(val) {
      if (val == 0) return "非电子标";
      if (val == 1) return "电子标";
    },
    // 过滤时间
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD");
    },
    // 过滤异常类型(1.暂停公告 2.恢复公告 3.终止公告 4.废除公告)
    filterAbnormalType(val) {
      if (val == 1) return "暂停公告";
      if (val == 2) return "恢复公告";
      if (val == 3) return "终止公告";
      if (val == 4) return "废除公告";
    }
  }
};
</script>

<style lang="less" scoped>
.abnormal-notice-detail {
  // 主体
  .main {
    // 标题
    .title {
      text-align: center;
      padding: 20px 0;
      border-bottom: 1px dashed #ECECF1;
      h1 {
        font-size: 20px;
        margin-bottom: 20px;
      }
      .release-date {
        color: #979797;
        font-size: 14px;
      }
    }

    // 招标信息
    .tender-info {
      background: #ecf5fb;
      padding: 20px;
      margin-bottom: 20px;
      font-size: 14px;
      .item {
        display: inline-block;
        line-height: 30px;
        margin-right: 30px;
        span {
          color: #979797;
        }
      }
    }

    // 内容
    .content {
      // border: 1px solid #deeaf3;
      padding: 20px;
      margin-bottom: 20px;
      .content-title {
        padding-left: 10px;
        border-left: 5px solid #3660d9;
        margin-bottom: 20px;
      }
      .content-text {
        color: #666666;
        font-size: 14px;
        margin-bottom: 20px;
      }
      .content-file {
        line-height: 20px;
        p {
          line-height: 48px;
          background: #ECECF1;
          font-size: 18px;
          font-weight: bold;
          color: rgba(32, 32, 30, 1);
          padding-left: 15px;
          margin-top: 35px;
        }
        .file_list {
          border-bottom: 1px dashed rgba(236, 236, 241, 1);
          line-height: 68px;
          display: flex;
          justify-content: space-between;
          color: rgba(32, 32, 30, 1);
          img {
            width: 24px;
            margin-right: 4px;
          }
        }
        a {
          color: #3660d9;
        }
      }
    }
  }
}
</style>